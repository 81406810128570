<template>
  <div class="home">
    <section class="message-container">
      <p>Music made in Phoenix, Arizona.</p>

      <p>You are the cosmos.</p>

      <p>We are all popcorn ceiling.</p>

      <p>Demos coming soon!</p>
    </section>

    <div class="image-container">
      <img
        class="center-fit"
        src="../assets/popcornceiling.jpg"
        alt="popcorn ceiling of popcorn ceiling"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView'
}
</script>

<style lang="scss" scoped>
.image-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.center-fit {
  width: 100%;
}

.message-container {
  width: 100vw;
}
</style>
