<template>
  <nav>
    <router-link to="/">Home</router-link>

    <router-link to="/contact">Contact</router-link>

    <a target="_blank" href="https://www.instagram.com/popcornceilingjam">
      Instagram
    </a>

    <a
      target="_blank"
      href="https://open.spotify.com/playlist/2s8U552udeUJyK7kxMIkDw?si=a3464bd6a0fe4c16"
      >Spotify Playlist</a
    >
  </nav>

  <h1 class="popcorn-ceiling">popcorn ceiling</h1>

  <router-view />
</template>

<style lang="scss">
#app {
  font-family: "Rubik Microbe", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
}

html {
  background-color: black;
  width: 100%;

  a {
    font-weight: 600;
    color: whitesmoke;
    margin-right: 1rem;

    &.router-link-exact-active {
      color: whitesmoke;
    }
  }

  p {
    font-weight: 600;
  }
}

nav {
  display: flex;
  justify-content: right;
  padding: 20px;

  a {
    font-weight: 600;
    color: whitesmoke;
    margin-right: 1rem;

    &.router-link-exact-active {
      color: whitesmoke;
    }
  }
}

.popcorn-ceiling {
  font-family: "Rubik Microbe", cursive;
  font-size: 6em;
  font-weight: 200;
  display: flex;
  justify-content: left;
  margin-top: -1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.image-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.center-fit {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  html {
    width: 100vw;

    a {
      font-size: 0.8em;
      font-weight: 600;
      color: whitesmoke;
      margin-right: 1rem;

      &.router-link-exact-active {
        color: whitesmoke;
      }
    }
  }

  .popcorn-ceiling {
    font-family: "Rubik Microbe", cursive;
    font-size: 4em;
    font-weight: 200;
    display: flex;
    justify-content: left;
    margin-top: -1rem;
    margin-bottom: 1rem;
    margin-left: -3rem;
  }
}
</style>
